import React from 'react';
// styles
import styles from './consultation-privacy-agreement.module.scss';

const ConsultationPrivacyAgreement: React.FC = () => {
  return (
    <div className={styles.iframe}>
      <h1>Your privacy matters!</h1>
      <p>
        For us to be able to provide our products and services to you, we (Sanofi) and our subcontractors will need your
        consent to collect, store, use and disclose your personal information on the basis specified in our{' '}
        <a href="">
          <strong>Privacy Notice</strong>
        </a>
        . We <strong>strongly encourage you to carefully read this document</strong> (and feel free to direct any
        enquiries to <a href="mailto:privacyofficer.australia@sanofi.com">privacyofficer.australia@sanofi.com</a>
        ).
      </p>
      <br />
      <br />
      <h2>We don't give medical advice!</h2>
      <p>
        Whilst care is taken to try to ensure that the information on our website is accurate, the information is of a{' '}
        <strong>general nature only and should not take the place of professional or medical advice</strong>. Please do
        not use any of the information on this website:
      </p>
      <ul>
        <li>to diagnose, prevent, treat or cure any disease or medical condition; or</li>
        <li>as a substitute for the advice of a health professional.</li>
      </ul>
      <p>
        We also recommend that, prior to using any of our products,{' '}
        <strong>
          you seek advice from a health professional where you have any pre-existing genetic (or any other medical)
          conditions or are taking any medication.
        </strong>
      </p>
      <br />
      <br />
      <h2>Consent</h2>
      <p>
        By clicking the button below, <strong>I confirm</strong> that I have read and understood the information above
        (including that contained in the links) and <strong>I agree</strong> to you and the third parties referenced in
        your <strong>Privacy Notice</strong> collecting, using, disclosing and storing my personal information on the
        basis specified in your Privacy Notice.
      </p>
    </div>
  );
};

export default ConsultationPrivacyAgreement;
